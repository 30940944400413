<div class="alert-container">
    <div
        class="d-flex flex-column justify-content-start align-items-start col-10"
    >
        <div
            [ngStyle]="{
                'border-bottom':
                    data?.fieldsAndMessage?.errors?.length > 0 || data?.message
                        ? ''
                        : 'none'
            }"
            class="d-flex flex-row align-items-center title-row"
        >
            @switch (data.type) { @case ('error') {
            <mat-icon>error_outline</mat-icon>
            } @default {
            <mat-icon>check_circle</mat-icon>
            } }
            <p class="title">{{ data.title }}</p>
        </div>
        @if (data.fieldsAndMessage?.errors?.length > 0) {
        <div
            class="d-flex flex-row msgField-row"
            *ngFor="let msg of data.fieldsAndMessage.errors"
        >
            <div class="field me-2">{{ msg.field }} :</div>
            <div class="message">{{ msg.message }}</div>
        </div>
        } @else if(data.message) {
        <div class="d-flex flex-row msg-row">{{ data.message }}</div>
        }
    </div>
    <button class="button col-2" mat-button (click)="onOkClick()">OK</button>
</div>
