import { Component, Inject } from '@angular/core';
import { MessageData } from '../../models/bases/message-data';
import {
    MAT_SNACK_BAR_DATA,
    MatSnackBarRef,
} from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-alert-msg',
    standalone: true,
    imports: [MatButtonModule, MatIconModule, CommonModule],
    templateUrl: './alert-msg.component.html',
    styleUrl: './alert-msg.component.scss',
})
export class AlertMsgComponent {
    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: MessageData,
        private snackBarRef: MatSnackBarRef<AlertMsgComponent>
    ) {}
    onOkClick(): void {
        this.snackBarRef.dismiss();
    }
}
