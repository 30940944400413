import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ErrorResponse } from '../models/bases/base-api-response';
import { MessageData } from '../models/bases/message-data';
import { AlertMsgComponent } from '../shared/alert-msg/alert-msg.component';

@Injectable({
    providedIn: 'root',
})
export class AlertMsgService {
    constructor(
        private _snackbar: MatSnackBar,
        private _translateService: TranslateService
    ) {}

    defaultDuration: number = 5000;
    alertError(data: {
        msg?: string;
        title?: string;
        duration?: number;
        filedMessage?: ErrorResponse;
    }) {
        const messageData: MessageData = {
            title: data.title
                ? data.title
                : this._translateService.instant('alert.error.title'),
            message: data.msg,
            type: 'error',
            fieldsAndMessage: data.filedMessage,
        };
        this._snackbar.openFromComponent(AlertMsgComponent, {
            data: messageData,
            duration: data.duration ? data.duration : this.defaultDuration,
            panelClass: ['custom-snackbar-panel', 'error'],
        });
    }

    alertInstruction(data: { msg: string; title?: string; duration?: number }) {
        const messageData: MessageData = {
            title: data.title
                ? data.title
                : this._translateService.instant('alert.instruction.title'),
            message: data.msg,
            type: 'instruction',
        };
        this._snackbar.openFromComponent(AlertMsgComponent, {
            data: messageData,
            duration: data.duration ? data.duration : this.defaultDuration,
            panelClass: ['custom-snackbar-panel', 'instruction'],
        });
    }

    alertSuccess(data: { msg: string; title?: string; duration?: number }) {
        const messageData: MessageData = {
            title: data.title
                ? data.title
                : this._translateService.instant('alert.success.title'),
            message: data.msg,
            type: 'success',
        };
        this._snackbar.openFromComponent(AlertMsgComponent, {
            data: messageData,
            duration: data.duration ? data.duration : this.defaultDuration,
            panelClass: ['custom-snackbar-panel', 'success'],
        });
    }

    alertWarning(data: { msg: string; title?: string; duration?: number }) {
        const messageData: MessageData = {
            title: data.title
                ? data.title
                : this._translateService.instant('alert.warning.title'),
            message: data.msg,
            type: 'warning',
        };
        this._snackbar.openFromComponent(AlertMsgComponent, {
            data: messageData,
            duration: data.duration ? data.duration : this.defaultDuration,
            panelClass: ['custom-snackbar-panel', 'warning'],
        });
    }
}
