export enum UserRoleEnum {
    USER = 'user',
    ADMIN = 'admin',
    OFFICER = 'officer',
    REQUESTER = 'requester',
    APPROVER = 'approver',
    SYSTEM_ADMIN = 'systemAdmin',
    SELLER = 'seller',
    RESELLER = 'reseller',
    DEVELOPER = 'developer',
    UNKNOWN = 'unknown',
}

export enum UserRoleEnumInAdmin {
    USER = 'user',
    ADMIN = 'admin',
    SELLER = 'seller',
    RESELLER = 'reseller',
    DEVELOPER = 'developer',
    // OFFICER = 'officer',
    // REQUESTER = 'requester',
    // APPROVER = 'approver',
    // SYSTEM_ADMIN = 'systemAdmin',
}
